import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="container">
      
      <div className="header">
        {/* L'Url di Munch */}
      </div>

      <div className="content">
       <div className="clickableArea">
         {/* Hover */}
         Coming soon!
         </div>
      </div>

      <div className="footer"> © L'Url di Munch 2020 | 
        <a href="https://francescaschiro.github.io/personal/" target="_blank" title="Check it out!">Francesca Schiro</a>   
      </div>

      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          L'<code>URL</code> di Munch.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
         bitches
        </a>
      </header> */}
    </div>
  );
}

export default App;
